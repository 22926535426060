#about-page {
  .about-banner {
    min-height: 450px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
  }

  .about-card {
    padding: 1rem;
    background-color: $primary-o-lighter;
    border-radius: 8px;
  }
  .table-financial {
    td {
      color: $text-secondary-dark;
    }
  }
}
