div#site-content {
  min-height: 50vh;
  position: relative;

  .main-container {
    background: $white;
    padding-top: $margin-top-main-container;
    @media only screen and (max-width: $break-large-min-1) {
      padding-top: $margin-top-main-container-large;
    }
    .sticky-top {
      top: $margin-top-main-container;
      @media only screen and (max-width: $break-extra-large-min-1) {
        top: $margin-top-main-container-large;
      }
    }

    .hero-background-container {
      background-position: bottom center;
      @media only screen and (min-width: $break-extra-large-min-1) {
        background-position: bottom center;
      }
      background-repeat: no-repeat;
      background-size: cover;
    }
    .hero-background-reverse-container {
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 2rem;
      @media only screen and (max-width: $break-small) {
        background: none !important;
        color: $text-secondary-dark !important;

      }
      @media only screen and (min-width: $break-large-min-1) {
        background-position: top center;
        padding-top: 2.5rem;
      }
      @media only screen and (min-width: $break-extra-large-min-1) {
        background-position: top center;
        padding-top: 7.5rem;
      }
    }
  }
}
