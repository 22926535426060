div#site-footer {
  .contact-section {
    background-color: $extra-color-main-08;
    .logo-footer {
      width: 262px;
    }
  }
  .contact-section-2 {
    background-color: $extra-color-main-016;
    ol {
      padding-left: 1rem;
      margin: 0;
    }
  }
  a {
    color: $text-primary;
    text-decoration: none;
    &:hover {
      color: $primary-main;
    }
  }
}
