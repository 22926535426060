/* general */
body {
  font-family: "Lato", Arial, Helvetica, sans-serif !important;
  color: $text-primary;
  background-color: $white;
  font-size: $sub-body-2;
  line-height: $line-height-sub-body-2;
  letter-spacing: 0.02em;
  font-weight: 400;

  @media (max-width: $break-small) {
    font-size: $caption;
    line-height: $line-height-caption;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

b,
strong {
  font-weight: 700 !important;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  border: none;
  filter: blur(0) !important;
  -webkit-filter: blur(0) !important;
  transform: translateZ(0) !important;
  -webkit-transform: translateZ(0) !important;
  image-rendering: -webkit-optimize-contrast !important;
}

.text-white {
  color: $white !important;
}

.text-primary {
  color: $text-primary !important;
}

.text-secondary {
  color: $text-secondary !important;
}
.text-secondary-dark {
  color: $text-secondary-dark !important;
}
.text-primary-main {
  color: $primary-main !important;
}
.text-error-main{
  color: $error-main !important;
}

.border-primary-main {
  border-color: $primary-main !important;
}
.border-secondary-main {
  border-color: $secondary-main !important;
}

@for $i from 2 through 4 {
  .text-truncate-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-secondary-o-lighter {
  background-color: $secondary-o-lighter;
}
.bg-extra-color-main-06 {
  background-color: $extra-color-main-06;
}
