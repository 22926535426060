#site-header {
  top: 0;
  z-index: 2 !important;
  width: 100%;
  .navbar-brand {
    img {
      width: 175px;
    }
  }
  .nav-item {
    a {
      text-decoration: none;
      color: $text-primary;
      font-weight: 700;
      &:hover,
      &:focus {
        color: $primary-main;
      }
    }
  }

  .nav-item.dropdown {
    .dropdown-menu {
      margin: 0;
      background: $white;
      padding: 0.5rem;
      .dropdown-item {
        font-weight: 400;
        padding: 0.25rem 0.75rem;
        color: $text-primary;
        &:hover {
          border-radius: 1rem;
          background-color: $primary-o-lighter;
          color: $primary-main;
        }
      }
    }
    &.hide-dropdown-icon {
      .dropdown-toggle::after {
        content: unset;
      }
    }

    &.dropdown-tkb {
      background-color: $primary-100;
      border-radius: 8px;
      .dropdown-toggle {
        font-weight: 400;
        color: $text-primary;
        padding: 0 8px;
        &:hover {
          color: $text-primary;
        }
      }
      .dropdown-menu {
        border: none;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
        .dropdown-item {
          border-radius: 0;
          &:first-child {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          background-color: $primary-100;
          padding: 2px 8px;
          &:hover {
            background-color: $primary-100;
            color: $text-primary;
            font-weight: 400;
            border-radius: 0;
            &:first-child {
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;
            }
            &:last-child {
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
            }
          }
        }
      }
    }
  }
}

.tkb-carousel {
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-indicators {
    button {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: $secondary-o-light !important;
      &.active {
        background-color: $primary-main !important;
      }
    }
  }
}
