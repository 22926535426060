#product-page {
  .product-banner {
    min-height: 450px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
    .product-banner-info {
      position: absolute;
      @media (max-width: $break-extra-large) {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    .banner-product-title {
      @media (min-width: $break-large) {
        white-space: nowrap;
      }
    }
  }
  .product-benefit-card {
    margin-top: 76px;
    padding: calc(76px + 1rem) 1rem 1rem;
    height: calc(100% - 70px) !important;
    background-color: $primary-o-lighter;
    text-align: center;
    .product-benefit-icon {
      top: -70px;
      left: calc(50% - 70px);
      img {
        width: 140px;
      }
    }
  }
  .product-how-number {
    border-radius: 50%;
    background-color: $primary-o-lighter;
    height: 47px;
    width: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-search {
    display: flex;
    flex-direction: row;
    border-color: #949494;
    border-style: solid;
    width: 100%;
    padding: 2px;
    border-width: 1px;
    border-radius: 8px;
    .form-input-search {
      color: #000;
      background-color: transparent;
      border-style: none;
      flex: 1;
      width: 100%;
    }
    button {
      width: max-content;
      min-width: 77px;
      max-width: 77px;
    }
  }
}
