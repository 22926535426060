.container {
  --bs-gutter-x: 1.25rem;
}

// ::placeholder {
//   /* Chrome, Firefox, Opera, Safari 10.1+ */
//   color: $light-grey !important;
// }

// :-ms-input-placeholder {
//   /* Internet Explorer 10-11 */
//   color: $light-grey !important;
// }

// ::-ms-input-placeholder {
//   /* Microsoft Edge */
//   color: $light-grey !important;
// }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.btn {
  border-radius: 8px;
  &.btn-primary {
    color: $white;
    background-color: $primary-main;
    border-color: $primary-main;
    &:hover {
      background-color: $primary-900;
      border-color: $primary-900;
    }
    &.white-hover:hover {
      background-color: $white;
      color: $primary-main;
    }
  }
  &:focus {
    box-shadow: none !important;
  }

  &.btn-white {
    color: $primary-main;
    background-color: $white;
    border-color: $white;
    &:hover {
      background-color: $primary-900;
      border-color: $primary-900;
      color: $white;
    }
  }
}

a,
.btn-link {
  color: $primary-main;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: $primary-900;
  }

  &.text-secondary:hover {
    color: $text-primary;
  }
}

.modal-content {
  padding: 1rem;
  color: $text-primary;
  .modal-header {
    border: 0;
    position: relative;
    .modal-title {
      padding: 0 1rem;
      font-size: $body-head;
      line-height: $line-height-body-head;
      flex: 1;
      @media (max-width: $break-small) {
        font-size: $body-head-2;
        line-height: $line-height-body-head-2;
      }
    }
    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.card {
  border-radius: 8px;
  box-shadow: $secondary-box-shadow-medium;
  &.card-hover {
    border-width: 2px;
    border-color: $white;
    &:hover {
      border-width: 2px;
      border-color: $primary-main;
      box-shadow: $primary-box-shadow-medium;
    }
  }
}

.text-danger,
.text-error {
  color: $error-main;
}

.form-check {
  @media (max-width: $break-small) {
    margin-top: 4px;
  }
  &.rounded-circle {
    .form-check-input {
      border-radius: 50%;
    }
  }
  .form-check-input {
    &:focus {
      box-shadow: none;
    }
    &:checked {
      background-color: $primary-main;
      border-color: $primary-main;
      &[type="radio"] {
        background-color: $white;
        border-width: 5px;
        background-image: none;

        @media (max-width: $break-medium) {
          border-width: 6px;
        }
        @media (max-width: $break-small) {
          border-width: 7px;
        }
      }
    }
  }
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: $primary-main;
  & ~ .form-check-label {
    color: $text-primary;
  }
  &:checked {
    background-color: $primary-main;
    &[type="radio"] {
      background-color: $white;
      border-width: 7px;
      background-image: none;
    }
  }
}

.fw-extra-bold {
  font-weight: 900;
}

.rc-slider-handle,
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $primary-main !important;
  border: solid 16px $primary-main;
  box-shadow: none;
  height: 12px;
  width: 12px;
  margin-top: -14px;
  background-color: $primary-main;
  opacity: 1;
}
.rc-slider-track {
  background-color: $primary-main;
  height: 6px;
  border-radius: 6px;
}
.rc-slider-rail {
  background-color: $extra-color-main-016;
}

input.form-control,
textarea.form-control {
  background-color: $primary-o-lighter;
  border: 2px solid $primary-o-lighter;
  color: $primary-main;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: $sub-body-2;
  &:focus {
    background-color: $primary-o-lighter;
    border: 2px solid $primary-o-main;
    box-shadow: none;
    outline: none;
    color: $primary-main;
  }
  &::placeholder {
    color: $text-disabled;
    opacity: 0.8;
  }
}
.phone-input-group {
  .phone-wrapper {
    display: flex;
    // background-color: $primary-o-lighter;

    .country-code {
      display: flex;
      align-items: center;
      background-color: $primary-o-main;
      color: $primary-main;
      border: 2px 0 2px 2px solid $primary-o-main;
      font-weight: bold;
      border-radius: 8px 0 0 8px;
    }

    .phone-number-input {
      border-radius: 0 8px 8px 0;
    }
  }

}
