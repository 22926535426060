#faq-page {
    .faq-banner {
      min-height: 450px;
      @media (min-width: $break-extra-large) {
        min-height: 650px;
      }
    }
    // .custom-accordion .accordion-item {
    //     margin-bottom: 10px; 
    //     border: 2px solid #28a745; 
    //   }
      
    //   .custom-accordion .accordion-item:last-child {
    //     margin-bottom: 0; 
    //   }
      
    //   .custom-accordion .accordion-header {
    //     font-weight: bold; 
    //   }
      
    //   .custom-accordion .accordion-body {
    //     background-color: #f9f9f9; 
    //     border-top: 1px solid #28a745; 
    //   }

    //   .accordion-button:not(.collapsed) {
    //     color: inherit !important;
    //     background-color: inherit !important;
    //     box-shadow: none !important;
    //   }
    //   .accordion-button:not(.collapsed) {
    //     color: inherit !important;
    //     background-color: inherit !important;
    //     box-shadow: none !important;
    //   }

    .custom-accordion {
      .accordion-item {
        margin-bottom: 10px;
        border: 2px solid #28a745;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    
     
      .accordion-body {
        background-color: #f9f9f9;
        border-top: 1px solid #28a745;
      }
    
      .accordion-button {
        &:not(.collapsed) {
          color: inherit !important;
          background-color: inherit !important;
          box-shadow: none !important;
        }
      }
    }
    
      
  }
  