#riplay-page {
  .riplay-banner {
    min-height: 500px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
  }

  .main-list {
    li {
      &::marker {
        font-weight: bold;
      }
    }
    .sub-list {
      li {
        list-style-type: lower-alpha;
        &::marker {
          font-weight: bold;
        }
      }
      .list-circle{
        li{
            list-style-type: circle;
        }
      }
    }
  }
}
