#tnc-page {
  .privacy-banner {
    min-height: 450px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
  }

  .list-content {
    list-style-image: url(../images/vector.png);
    counter-reset: list-item;
  }

  .main-list {
    list-style-type: none;
    padding-left: 0;
    counter-reset: main-counter;

    li {
      display: flex;
      align-items: baseline;
      gap: 8px;
      color: $text-primary;

      font-size: $sub-body-2;
      line-height: $line-height-sub-body-2;
      @media (max-width: $break-extra-large) {
        font-size: $caption;
        line-height: $line-height-caption;
      }
      @media (max-width: $break-large) {
        font-size: $label;
        line-height: $line-height-label;
      }

      &::before {
        content: counter(main-counter) ". ";
        counter-increment: main-counter;
        margin-right: 0.5rem;
        font-weight: bold;
        color: $text-primary;
        width: 30px;
      }
      .list-numbering {
        list-style: none;
        counter-reset: list-numbering;
        padding-left: 0;

        li {
          &::before {
            content: counter(list-numbering, decimal) ". ";
            counter-increment: list-numbering;
            color: $text-primary;
            font-weight: normal;
          }
        }
      }

      .sub-list {
        list-style-type: none;
        padding-left: 0;
        counter-reset: sub-counter;

        li {
          color: $text-primary;

          &::before {
            content: counter(main-counter) "." counter(sub-counter) ". ";
            counter-increment: sub-counter;
            color: $primary-main;
            flex-shrink: 0;
            width: 30px;
            text-align: left;
            font-weight: normal;
          }
          .sub-list2 {
            list-style-type: none;
            padding-left: 0;
            counter-reset: sub-sub-counter;

            li {
              display: list-item;

              &::before {
                content: counter(main-counter) "." counter(sub-counter) "."
                  counter(sub-sub-counter) ". ";
                counter-increment: sub-sub-counter;
                color: $primary-main;
              }
            }
          }
          .list-abjad {
            list-style: none;
            counter-reset: list-counter; 
            padding-left: 0;

            li {
              &::before {
                content: counter(list-counter, lower-alpha) ". ";
                counter-increment: list-counter;
                color: $text-primary;
              }
            }
          }
        }
      }
    }
  }
}
