#contact-page {
  .contact-content {
    min-height: 450px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
  }

  .contact-form {
    .error-message {
      color: $error-main;
      font-size: 12px;
      height: 10px;
      margin-top: 5px;
      line-height: 16px;
    }
  }

  .contact-info-container {
    position: relative;
    width: 100%;
    height: 580px;
    background: url("../images/map-mayapada.png") no-repeat;
    background-size: 100%;
    background-position: 0 4rem;
  }
}
