#complaint-page {
    .complaint-banner {
      min-height: 450px;
      @media (min-width: $break-extra-large) {
        min-height: 600px;
      }
    }
      .complaint-card {
        margin-top: 36px;
        padding: calc(36px + 1rem) 1rem 1rem;
        height: calc(100% - 10px) !important;
        background-color: $primary-o-lighter;
        text-align: center;
        .complaint-number {
        top: -20px; 
        left: 50%; 
        transform: translateX(-50%); 
        width: 50px; 
        height: 50px; 
        line-height: 40px; 
        border: 2px solid ; 
        background-color: #fff; 
        border-radius: 50%; 
        color: $primary-main; 
        font-weight: bold; 
        font-size: 25px; 
        text-align: center; 
        justify-content: center;
        }
      }

      .content-card{
        border:2px solid;
        padding: 24px 40px 24px 40px;
        border-color: $primary-500;
      }

    .listAlpha{
      list-style-type: lower-alpha ;
      li{
        &::marker {
          font-weight: bold;
        }
      }
    }
  }
  