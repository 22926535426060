#home-page {
  .home-banner {
    min-height: 450px;
    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
    .home-banner-info {
      position: absolute;
      @media (max-width: $break-extra-large) {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    .banner-product-title {
      @media (min-width: $break-large) {
        white-space: nowrap;
      }
    }
  }
}
