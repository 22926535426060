$break-small: 576px;
$break-small-up-1: 577px;
$break-medium: 768px;
$break-medium-min-1: 767px;
$break-medium-up-1: 769px;

$break-large: 992px;
$break-large-min-1: 991px;
$break-large-up-1: 993px;

$break-extra-large: 1200px;
$break-extra-large-min-1: 1199px;
$break-extra-large-up-1: 1201px;

$margin-top-main-container: 3.6rem;
$margin-top-main-container-large: 4.5rem;
