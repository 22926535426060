@import "@fontsource/lato";
@import "@fontsource/lato/400.css";
@import "@fontsource/lato/700.css";
@import "@fontsource/lato/900.css";

$title-1: 60px;
$title-2: 56px;
$title-3: 52px;
$headline: 46px;
$sub-headline: 38px;
$sub-headline-2: 32px;
$body-head: 28px;
$body-head-2: 24px;
$sub-body: 20px;
$sub-body-2: 18px;
$caption: 16px;
$label: 14px;
$label-2: 12px;
$label-3: 10px;

$line-height-title-1: 82px;
$line-height-title-2: 78px;
$line-height-title-3: 74px;
$line-height-headline: 68px;
$line-height-sub-headline: 56px;
$line-height-sub-headline-2: 50px;
$line-height-body-head: 42px;
$line-height-body-head-2: 38px;
$line-height-sub-body: 34px;
$line-height-sub-body-2: 28px;
$line-height-caption: 24px;
$line-height-label: 20px;
$line-height-label-2: 16px;
$line-height-label-3: 14px;

.text-size-title-2 {
  font-size: $title-2;
  line-height: $line-height-title-2;
  @media (max-width: $break-extra-large) {
    font-size: $title-3;
    line-height: $line-height-title-3;
  }
  @media (max-width: $break-large) {
    font-size: $headline;
    line-height: $line-height-headline;
  }
}
.text-size-sub-headline {
  font-size: $sub-headline;
  line-height: $line-height-sub-headline;
  @media (max-width: $break-extra-large) {
    font-size: $sub-headline-2;
    line-height: $line-height-sub-headline-2;
  }
  @media (max-width: $break-large) {
    font-size: $body-head;
    line-height: $line-height-body-head;
  }
}
.text-size-body-head {
  font-size: $body-head;
  line-height: $line-height-body-head;
  @media (max-width: $break-extra-large) {
    font-size: $sub-body;
    line-height: $line-height-body-head-2;
  }
  @media (max-width: $break-large) {
    font-size: $sub-body;
    line-height: $line-height-sub-body;
  }
}
.text-size-body-head-2 {
  font-size: $body-head-2;
  line-height: $line-height-body-head-2;
  @media (max-width: $break-extra-large) {
    font-size: $sub-body;
    line-height: $line-height-sub-body;
  }
  @media (max-width: $break-large) {
    font-size: $sub-body-2;
    line-height: $line-height-sub-body-2;
  }
}
.text-size-sub-body-2 {
  font-size: $sub-body-2;
  line-height: $line-height-sub-body-2;
  @media (max-width: $break-extra-large) {
    font-size: $caption;
    line-height: $line-height-caption;
  }
  @media (max-width: $break-large) {
    font-size: $label;
    line-height: $line-height-label;
  }
}
.text-size-caption {
  font-size: $caption;
  line-height: $line-height-caption;
  @media (max-width: $break-extra-large) {
    font-size: $label;
    line-height: $line-height-label;
  }
  @media (max-width: $break-large) {
    font-size: $label-2;
    line-height: $line-height-label-2;
  }
}
.text-size-label {
  font-size: $label;
  line-height: $line-height-label;
  @media (max-width: $break-extra-large) {
    font-size: $label-2;
    line-height: $line-height-label-2;
  }
}
