$primary-100: #cce9da;
$primary-200: #99d4b4;
$primary-300: #66be8f;
$primary-400: #33a969;
$primary-light: #33a969;
$primary-500: #009344;
$primary-main: #009344;
$primary-600: #00853d;
$primary-dark: #00853d;
$primary-700: #007d3a;
$primary-800: #007636;
$primary-900: #006e33;

$primary-o-lighter: rgba(0, 147, 68, 0.08);
$primary-o-light: rgba(0, 147, 68, 0.16);
$primary-o-main: rgba(0, 147, 68, 0.24);
$primary-o-dark: rgba(0, 147, 68, 0.32);
$primary-o-darker: rgba(0, 147, 68, 0.38);

$secondary-o-lighter: rgba(74, 74, 73, 0.08);
$secondary-o-light: rgba(74, 74, 73, 0.16);
$secondary-o-main: rgba(74, 74, 73, 0.24);
$secondary-o-dark: rgba(74, 74, 73, 0.32);
$secondary-o-darker: rgba(74, 74, 73, 0.38);

$primary-box-shadow-small: 0px 2px 6px 0px rgba(0, 147, 68, 0.3);
$primary-box-shadow-medium: 0px 4px 16px 0px rgba(0, 147, 68, 0.4);
$primary-box-shadow-large: 0px 6px 20px 0px rgba(0, 147, 68, 0.5);

$secondary-box-shadow-small: 0px 2px 6px 0px rgba(74, 74, 73, 0.3);
$secondary-box-shadow-medium: 0px 4px 16px 0px rgba(74, 74, 73, 0.4);
$secondary-box-shadow-large: 0px 6px 20px 0px rgba(74, 74, 73, 0.5);

$secondary-100: #dbdbdb;
$secondary-200: #b7b7b6;
$secondary-300: #929292;
$secondary-400: #6e6e6d;
$secondary-light: #6e6e6d;
$secondary-500: #4a4a49;
$secondary-main: #4a4a49;
$secondary-600: #434342;
$secondary-dark: #434342;
$secondary-700: #3f4043;
$secondary-800: #3b3b3a;
$secondary-900: #373737;

$error-main: #ee3135;

$text-primary: #5e5e5e;
$text-secondary: #828282;
$text-secondary-dark: #434342;
$text-disabled: #b8b8b8;
$white: #ffffff;

$extra-color-main-06: #f5f5f5;
$extra-color-main-08: #f1f1f1;
$extra-color-main-016: #e2e2e2;
