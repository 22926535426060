#partner-page {
  .about-banner {
    min-height: 450px;

    @media (min-width: $break-extra-large) {
      min-height: 600px;
    }
  }

  .filter-group {
    display: flex;
    gap: 10px;
  }

  .filter-button {
    display: flex;
    align-items: center;
    border: 2px solid;
    border-radius: 8px;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    &.active {
      background-color: #f1f1f1 !important;
    }

    span {
      display: inline-block;
    }
  }

  .partner-card {
    border: none;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 135px;
    width: 167px;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    span {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    .categoryProduct {
      position: relative;
      top: 10px;
      /* right: 10px; */
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
  .form-search{
    display: flex;
    flex-direction: row;
    border-color: #949494;
    border-style: solid;
    width: 100%;
    padding: 2px;
    border-width: 1px;
    border-radius: 8px;
    .form-input-search {
      color: #000;
      background-color: transparent;
      border-style: none;
      flex: 1,
    }
  }
}
